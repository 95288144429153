import React from 'react';
import { graphql } from 'gatsby';
import { HeadingText } from 'shared/components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostsGrid from '../components/PostsGrid';
import { Site, ConnectedItems } from '../types';

type Props = {
  pageContext: {
    tag: string;
  };
  data: {
    allMarkdownRemark: {
      totalCount: number;
      edges: ConnectedItems[];
    };
    site: Site;
  };
  location: Location;
};

const Tags = ({ data, pageContext, location }: Props) => {
  const siteTitle = data.site.siteMetadata.title;
  const { edges: posts, totalCount } = data.allMarkdownRemark;
  const { tag = '' } = pageContext;
  const tagHeader = tag
    ? `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with "${tag}"`
    : '';

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={tag} />

      <HeadingText as="h1" mt={6} mx={6}>
        {tagHeader}
      </HeadingText>

      <PostsGrid posts={posts} />
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } }, published: { eq: true } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM DD YY")
            title
            tags
            authors
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
